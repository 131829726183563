import map from 'lodash/map';
import { NETWORKS } from 'lib/constants';
import { NetworkIcon } from 'elements/NetworkIcon';
import { trackClickSocialMediaLinkEvent } from 'lib/hooks/useEventTracking';
import { User } from 'lib/types/users';
import { useApi } from 'lib/contexts/ApplicationState';
import { MailButton } from 'components/MailButton';

interface Props {
  profileUser: User;
  showColor?: boolean;
}

export const SocialMediaRow = ({ profileUser, showColor }: Props) => {
  const API = useApi();

  const hasSocialMediaOrContact = () => {
    const {
      facebookUrl, instagramUrl, email, twitterUrl, tiktokUrl,
      youtubeUrl, behanceUrl, personalUrl, linkedinUrl, twitchUrl,
    } = profileUser;
    return !!(profileUser && ((email && profileUser?.publicSettings?.contactAllowed)
    || facebookUrl || instagramUrl || twitterUrl || tiktokUrl || youtubeUrl
    || behanceUrl || personalUrl || linkedinUrl || twitchUrl));
  };

  if (!hasSocialMediaOrContact()) {
    return null;
  }

  return (
    <div id="profile-social-media" className="flex flex-wrap justify-center gap-4">
      {profileUser.email && profileUser?.publicSettings?.contactAllowed
    && (<MailButton user={profileUser} />)}
      {map(NETWORKS, ((network, id) => {
        const networkId = parseInt(id, 10);
        const networkUrl = (profileUser as any)[network.userUrl] || '';
        if (!networkUrl.length) {
          return null;
        }
        return (
          <a
            key={networkId}
            href={networkUrl}
            title={`open ${network.title}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackClickSocialMediaLinkEvent(
              API, network.name, profileUser.id,
            )}
          >
            <NetworkIcon
              network={networkId}
              showColor={showColor}
            />
          </a>
        );
      }))}
    </div>
  );
};
